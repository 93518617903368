import React from "react";
import Footer from "./Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ProgressiveImage from "react-progressive-graceful-image";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import landingPageNewStyle from "assets/jss/material-kit-pro-react/views/landingPageNewStyle.js";
import face1 from "assets/img/footer/img1.png";
// import saiconnect from "assets/img/footer/saiconnectLogo.png";
import face3 from "assets/img/footer/img3.png";
import face4 from "assets/img/footer/img4.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Link } from "react-router-dom";

export default function WebsiteFooter() {
  const useStyles = makeStyles(landingPageNewStyle);
  const email = "SP.DL@SSSSOINDIA.ORG".toLowerCase();
  const classes = useStyles();

  return (
    <Footer
      theme="transparent"
      content={
        <div>
          <div className={classes.center}>
            Sri Sathya Sai Seva Organisations Delhi-NCR. All Rights Reserved.
          </div>
        </div>
      }
      backgroundColor="#eee"
    >
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <h5>SRI SATHYA SAI SEVA ORGANISATIONS DELHI-NCR</h5>
          <p>
            The Sri Sathya Sai Seva Organisations was founded in the year of
            1960 by Bhagawan Sri Sathya Sai Baba to enable its members to
            undertake service activities as a means to spiritual advancement.
          </p>
          <hr />

          <br />
          <div>
            <h5>Contact Us:</h5>
            <h6>
              <b className={classes.email}>Email Id:-</b>
              <h7 className={classes.email}>{email}</h7>
            </h6>
            <h6>
              <b className={classes.email}>Mobile Number:-</b>9971999992
            </h6>
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Social Feed</h5>
          <div className={classes.socialFeed}>
            <div>
              <a
                href="https://www.facebook.com/SaiDelhiNCR"
                target="_blank"
                style={{ color: "inherit" }}
              >
                <FacebookIcon />
              </a>
              <p>
              SSSSO, Delhi-NCR Facebook
              </p>
            </div>

            <div>
              <a
                href="https://www.twitter.com/SaiDelhiNCR"
                target="_blank"
                style={{ color: "inherit" }}
              >
                <TwitterIcon />
              </a>
              <p>
              SSSSO, Delhi-NCR Twitter
              </p>
            </div>

            <div>
              <a
                href="https://www.youtube.com/channel/UC7OwkgiCNrdEQFkUAy-4EEA"
                target="_blank"
                style={{ color: "inherit" }}
              >
                <YouTubeIcon />
              </a>
              <p>
              SSSSO, Delhi-NCR YouTube
              </p>
            </div>
          </div>
          <h5>Important Website Links</h5>
          <div className={classes.galleryFeed}>
            <a href="https://psg.sssihms.org.in" target="_blank">
              {/* <img
                  src={face1}           
                  className={classNames(
                    classes.img,
                    classes.imgRounded
                  )}
                  alt="..."
                /> */}
              <ProgressiveImage src={face1} placeholder="tiny-image.jpg">
                {(src, loading) => (
                  <img
                    style={{ opacity: loading ? 0.4 : 1 }}
                    src={src}
                    className={classNames(classes.img, classes.imgRounded)}
                    alt="..."
                  />
                )}
              </ProgressiveImage>
            </a>

            <a href="https://www.saiconnect.app/" target="_blank">
              <ProgressiveImage src={"/assets/images/footer/saiConnect.png"} placeholder="tiny-image.jpg">
                {(src, loading) => {
                  console.log("src", src);
                  return (
                    <img
                      style={{ opacity: loading ? 0.5 : 1 }}
                      src={src}
                      className={classNames(classes.img, classes.imgRounded)}
                      alt="..."
                    />
                  );
                }}
              </ProgressiveImage>
            </a>

            <a href="http://www.radiosai.org" target="_blank">
              {/* <img
                  src={face3}
                  className={classNames(classes.img, classes.imgRounded)}
                  alt="..."
                /> */}
              <ProgressiveImage src={face3} placeholder="tiny-image.jpg">
                {(src, loading) => (
                  <img
                    style={{ opacity: loading ? 0.4 : 1 }}
                    src={src}
                    className={classNames(classes.img, classes.imgRounded)}
                    alt="..."
                  />
                )}
              </ProgressiveImage>
            </a>
            <a href="http://www.srisathyasai.org/pages" target="_blank">
              <ProgressiveImage src={face4} placeholder="tiny-image.jpg">
                {(src, loading) => (
                  <img
                    style={{ opacity: loading ? 0.4 : 1 }}
                    src={src}
                    className={classNames(classes.img, classes.imgRounded)}
                    alt="..."
                  />
                )}
              </ProgressiveImage>
            </a>
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Donations:</h5>
          <div className={classes.galleryFeed}>
            Indian Nationals and Non Resident Indians (NRI) with Indian passport can remit their donations by RTGS/NEFT. Contributions to the Trust enjoy exemption under Section 80G of the Income Tax Act.<br/><br/> Please visit the donations page for details:
            <br/><u><Link to="/donate">Click here</Link></u>
          </div>
         
        </GridItem>
      </GridContainer>
    </Footer>
  );
}
