/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import Delete from "@material-ui/icons/Delete";
import FormatQuote from "@material-ui/icons/FormatQuote";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
// @material-ui/core components

// import useLazyLoadImage from 'react-use-lazy-load-image';
import ProgressiveImage from "react-progressive-graceful-image";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Grid from "@material-ui/icons/GridOn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ListItem from "@material-ui/core/ListItem";
import FormatPaint from "@material-ui/icons/FormatPaint";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Carousel from "react-slick";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Parallax from "components/Parallax/Parallax.js";
import { Parallax, Background } from "react-parallax";

import Subject from "@material-ui/icons/Subject";

// import dg1 from "assets/img/dg1.jpg";
// import dg2 from "assets/img/dg2.jpg";
// import dg3 from "assets/img/dg3.jpg";

import image2 from "assets/img/home/banner_try11.jpeg";
import bannerImg from "assets/img/baba/SSSSO_Logo.png";
// import face1 from "assets/img/faces/card-profile6-square.jpg";
// import face2 from "assets/img/faces/christian.jpg";
// import face3 from "assets/img/faces/card-profile4-square.jpg";
// import face4 from "assets/img/faces/card-profile1-square.jpg";
import face5 from "assets/img/faces/marc.jpg";
import face6 from "assets/img/faces/kendall.jpg";
import face7 from "assets/img/faces/card-profile5-square.jpg";
import face8 from "assets/img/faces/card-profile2-square.jpg";
import Code from "@material-ui/icons/Code";
import face1 from "assets/img/footer/img1.png";
import face3 from "assets/img/footer/img3.png";
import face4 from "assets/img/footer/img4.png";
import InfoArea from "components/InfoArea/InfoArea.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import service2 from "assets/img/home/wings/service2.jpg";
import spritual from "assets/img/home/wings/spritual.jpg";
import education1 from "assets/img/home/wings/education1.jpg";
import imageRotating1 from "assets/img/home/rotatingImages/imageRotating1.jpeg";
import imageRotating2 from "assets/img/home/rotatingImages/imageRotating2.jpeg";
import imageRotating3 from "assets/img/home/rotatingImages/imageRotating3.jpeg";
import talkBySwami from "assets/img/home/rotatingImages/talkBySwami.jpeg";
import musicBand from "assets/img/home/rotatingImages/musicBand.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog7 from "assets/img/examples/blog7.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";
import TrendingUp from "@material-ui/icons/TrendingUp";
import ButtonMaterialUI from "@material-ui/core/Button"

import "animate.css/animate.min.css";

import ScrollAnimation from "react-animate-on-scroll";
import presentationiPad from "assets/img/assets-for-demo/presentationViewSectionComponent/presentation-ipad.jpg";
import presentationiPadComments from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-comments.jpg";
import presentationiPadTable from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-table.jpg";

// import presentationiPad from "assets/img/assets-for-demo/presentationViewSectionComponent/swami-1.jpeg";
// import presentationiPadComments from "assets/img/assets-for-demo/presentationViewSectionComponent/swami-2.jpeg";
// import presentationiPadTable from "assets/img/assets-for-demo/presentationViewSectionComponent/swami-3.jpeg";

import SectionOverview from "views/PresentationPage/Sections/SectionOverview.js";

import landingPageNewStyle from "assets/jss/material-kit-pro-react/views/landingPageNewStyle.js";

// Sections for this page
// import SectionProduct from "./Sections/SectionProduct.js";
// import SectionTeam from "./Sections/SectionTeam.js";
// import SectionWork from "./Sections/SectionWork.js";
import { Link } from "react-router-dom";
const useStyles = makeStyles(landingPageNewStyle);

export default function LandingNewPage({ ...rest }) {
  const bannerImageLinks = [
    // "/assets/images/baba/banner1.png",
    "/assets/images/baba/banner2.jpg",
    "/assets/images/baba/banner3.jpg",
    "/assets/images/baba/banner4.jpg",
    "/assets/images/baba/banner5.jpg",
    "/assets/images/baba/banner6.jpg",
    "/assets/images/baba/banner7.jpg",
    "/assets/images/baba/banner8.jpg",
  ];

  // useLazyLoadImage();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = cardHeight + 100 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 100 + "px";
      cardBack.style.width = cardWidth + "px";
      // console.log(" cardBack.style.width", cardBack.style.width);
    }
  };
  const classes = useStyles();
  return (
    <div>
      {/* <Parallax image={require("assets/img/baba/babaimages.jpg")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Every landing page needs a small description after the big bold
                title, that{"'"}s why we added this text here. Add here all the
                information that can make you or your product create the first
                impression.
              </h4>
              <br />
              <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
              >
                <i className="fas fa-play" />
                Watch video
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      <Carousel {...settings}>
        {/* Carousel 3 START */}

        {/* Carousel 3 END */}

        {bannerImageLinks.map((link) => (
          <div>
            <ProgressiveImage src={spritual}>
              {(src, loading) => (
                <div
                  className={classes.pageHeader}
                  style={{ backgroundImage: `url(${link})` }}
                >
                  <div className={classes.container}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}></GridItem>
                    </GridContainer>
                  </div>
                </div>
              )}
            </ProgressiveImage>
          </div>
        ))}

        {/* Carousel 2 END */}

        {/* Carousel 1 START */}
        {/* <div>
          <ProgressiveImage src={spritual}>
            {(src, loading) => (
              <div
                className={classes.pageHeader}
                style={{
                  backgroundImage: `url("${image1}")`,
                  opacity: loading ? 0.5 : 1,
                }}
              >
                <div className={classes.container}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/watch?v=cED8rLk0XQE&t=864s"
                      >
                        <Button color="danger" size="lg">
                          Watch Now
                        </Button>
                      </a>
                    </GridItem>
                  </GridContainer>
                </div>               
              </div>
            )}
          </ProgressiveImage>
        </div> */}
        {/* Carousel 1 END */}
      </Carousel>
      <div
        className={classNames(
          classes.main,
          classes.overrideMaiRaised,
          classes.mainRaised
        )}
      >
        <div className={classes.container}>
          {/* <SectionProduct />
          <SectionTeam />
          <SectionWork /> */}
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Latest Blogposts 2</h2>
              <br />
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.itemposition}
                >
                  <div>
                    <h2>Help Ever Hurt Never</h2>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${bannerImg})`,
                      width: "120px",
                      height: "120px",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div>
                    <h2>Love All Serve All</h2>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.itemposition}
                >
                  <div>
                    <br />
                    <ButtonMaterialUI variant="contained" color="secondary" size="large" onClick={
                      () => { }
                    }>
                      <Link to="/donate" style={{ color: "white" }}>
                        Donate
                      </Link>
                    </ButtonMaterialUI>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <Link to={"/spritual-wing"}>
                        <a href="#">
                          {/* <img src={spritual} alt="..." /> */}
                          {/* <img src={spritual} alt="..."/> */}
                          <ProgressiveImage
                            src={spritual}
                            placeholder="tiny-image.jpg"
                          >
                            {(src, loading) => (
                              <img
                                style={{ opacity: loading ? 0.5 : 1 }}
                                src={src}
                                alt="..."
                              />
                            )}
                          </ProgressiveImage>
                        </a>
                      </Link>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog5})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <Success>
                        <h4 className={classes.cardCategory}>Spiritual Wing</h4>
                      </Success>
                      <h4 className={classes.cardTitle}>
                        <Link to={"/spritual-wing"}>
                          <a href="#">
                            The spiritual wing helps one tread on the pathway to
                            God through Bhakti Yoga (Path of devotion).
                          </a>
                        </Link>
                      </h4>
                      <p className={classes.description}>
                        Spiritual activities include devotional singing, study
                        circles, public lectures, seminars, meditation etc.
                        <Link to={"/spritual-wing"}>
                          <a href="#"> Read More </a>
                        </Link>
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card className="mainCardHover" plain blog>
                    <CardHeader plain image>
                      <Link to={"/service-wing"}>
                        <a href="#">
                          {/* <img src={service2} alt="..." /> */}
                          <ProgressiveImage
                            src={service2}
                            placeholder="tiny-image.jpg"
                          >
                            {(src, loading) => (
                              <img
                                style={{ opacity: loading ? 0.5 : 1 }}
                                src={src}
                                alt="..."
                              />
                            )}
                          </ProgressiveImage>
                        </a>
                      </Link>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <Info>
                        <h4 className={classes.cardCategory}>Service Wing</h4>
                      </Info>
                      <h4 className={classes.cardTitle}>
                        <Link to={"/service-wing"}>
                          <a href="#">
                            The service wing helps one to reach self realization
                            through Karma Yoga (Path of Action).
                          </a>
                        </Link>
                      </h4>
                      <p className={classes.description}>
                        Self less Service activities include medical camps,
                        Village development, Narayana Seva, blood donation, etc.
                        <Link to={"/service-wing"}>
                          <a href="#"> Read More </a>
                        </Link>
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <Link to={"/education-wing"}>
                        <a href="#">
                          {/* <img src={education1} alt="..." /> */}
                          <ProgressiveImage
                            src={education1}
                            placeholder={education1}
                          >
                            {(src, loading) => (
                              <img
                                style={{ opacity: loading ? 0.5 : 1 }}
                                src={src}
                                alt="..."
                              />
                            )}
                          </ProgressiveImage>
                        </a>
                      </Link>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog7})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                    <CardBody plain>
                      <Danger>
                        <h4 className={classes.cardCategory}>
                          <TrendingUp />
                          Education Wing
                        </h4>
                      </Danger>
                      <h4 className={classes.cardTitle}>
                        <Link to={"/education-wing"}>
                          <a href="#">
                            The Education Wing helps one reach self realization
                            through ‘Jnana Yoga’ (Path of Knowledge).
                          </a>
                        </Link>
                      </h4>
                      <p className={classes.description}>
                        Education activities include Human Values Programmes,
                        Balvikas, Study circles etc.
                        <Link to={"/education-wing"}>
                          <a href="#"> Read More </a>
                        </Link>
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className={classes.marginCard}
            >
              <div className={classes.rotatingCardContainer}>
                <Card background className={classes.cardRotate}>
                  <ProgressiveImage
                    src={imageRotating2}
                    placeholder="tiny-image.jpg"
                  >
                    {(src, loading) => (
                      <div
                        className={
                          classes.front + " " + classes.wrapperBackground
                        }
                        style={{
                          backgroundImage: `url(${imageRotating2})`,
                        }}
                      >
                        <CardBody background className={classes.cardBodyRotate}>
                          <h4 className={classes.cardCategoryWhite}>
                            Speaker : <b>Shri Nimish Pandya</b>
                          </h4>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <h3 className={classes.cardTitleWhite}>
                              <em>Reaching God via Digital Tools</em>
                            </h3>
                          </a>
                          {/* <p className={classes.cardDescriptionWhite}>
                                
                                    Don{"'"}t be scared of the truth because we need to
                                    restart the human foundation in truth And I love you
                                    like Kanye loves Kanye I love Rick Owens’ bed design
                                    but the back is...
                                  </p> */}
                        </CardBody>
                      </div>
                    )}
                  </ProgressiveImage>

                  <div
                    className={classes.back + " " + classes.wrapperBackground}
                    style={{
                      background: "#664f2b",
                    }}
                  >
                    <CardBody background className={classes.cardBodyRotate}>
                      {/* <h5 className={classes.cardTitleWhite}>Manage Post</h5>
                        <p className={classes.cardDescriptionWhite}>
                          As an Admin, you have shortcuts to edit, view or
                          delete the posts.
                        </p>
                        <div className={classes.textCenter}>
                          <Button round justIcon color="info">
                            <Subject />
                          </Button>
                          <Button round justIcon color="success">
                            <Icon>mode_edit</Icon>
                          </Button>
                          <Button round justIcon color="danger">
                            <Delete />
                          </Button>
                        </div> */}

                      <div className={classes.textCenter}>
                        <h3 style={{ color: "#fff" }}>
                          To Watch the video click on the below button.
                        </h3>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=bIJ30xA4NpA"
                        >
                          <Button color="danger" size="lg">
                            Watch
                          </Button>
                        </a>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className={classes.marginCard}
            >
              <div className={classes.rotatingCardContainer}>
                <Card background className={classes.cardRotate}>
                  <ProgressiveImage
                    src={talkBySwami}
                    placeholder="tiny-image.jpg"
                  >
                    {(src, loading) => (
                      <div
                        className={
                          classes.front + " " + classes.wrapperBackground
                        }
                        style={{
                          backgroundImage: `url(${talkBySwami})`,
                          opacity: loading ? 0.5 : 1,
                        }}
                      >
                        <CardBody background className={classes.cardBodyRotate}>
                          {/* <h4 className={classes.cardCategoryWhite}>
                            Speaker : <b>Shri Abhimanyu Kaul</b>
                          </h4> */}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <h3 className={classes.cardTitleWhite}>
                              <em>Talk by Swami Ganesha Swarupananda Saraswati</em>
                            </h3>
                          </a>
                          {/* <p className={classes.cardDescriptionWhite}>
                                     Don{"'"}t be scared of the truth because we need to
                                     restart the human foundation in truth And I love you
                                     like Kanye loves Kanye I love Rick Owens’ bed design
                                     but the back is...
                                   </p> */}
                        </CardBody>
                      </div>
                    )}
                  </ProgressiveImage>

                  <div
                    className={classes.back + " " + classes.wrapperBackground}
                    style={{
                      background: "#580b3c",
                    }}
                  >
                    <CardBody background className={classes.cardBodyRotate}>
                      {/* <h5 className={classes.cardTitleWhite}>Manage Post</h5>
                     <p className={classes.cardDescriptionWhite}>
                       As an Admin, you have shortcuts to edit, view or
                       delete the posts.
                     </p> */}
                      <div className={classes.textCenter}>
                        <h3 style={{ color: "#fff" }}>
                          To Watch the video click on the below button.
                        </h3>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=X3I9kf_mVzg"
                        >
                          <Button color="danger" size="lg">
                            Watch
                          </Button>
                        </a>
                      </div>
                      {/* <img src={youtubeIcon} style={{width:"100%" , height:"100%"}}/> */}
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem>

            {/*  second hover rotate item  */}

            {/* 
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className={classes.marginCard}
            >
              <div className={classes.rotatingCardContainer}>
                <Card background className={classes.cardRotate}>
                  <ProgressiveImage src={imageRotating2} placeholder="tiny-image.jpg">
                    {(src, loading) => (
                      <div
                        className={classes.front + " " + classes.wrapperBackground}
                        style={{
                          backgroundImage: `url(${imageRotating2})`,
                        }}
                      >
                        <CardBody background className={classes.cardBodyRotate}>
                          <h4 className={classes.cardCategoryWhite}>
                            Speaker : <b>Shri Nimish Pandya</b>
                          </h4>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <h3 className={classes.cardTitleWhite}>
                              <em>Reaching God via Digital Tools</em>
                            </h3>
                          </a>
                         
                        </CardBody>
                      </div>
                    )}
                  </ProgressiveImage>

                  <div
                    className={classes.back + " " + classes.wrapperBackground}
                    style={{
                      background: "#664f2b",
                    }}
                  >
                    <CardBody background className={classes.cardBodyRotate}>
                      

                      <div className={classes.textCenter}>
                        <h3 style={{ color: "#fff" }}>
                          To Watch the video click on the below button.
                        </h3>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=bIJ30xA4NpA"
                        >
                          <Button color="danger" size="lg">
                            Watch
                          </Button>
                        </a>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem> */}

            {/* <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className={classes.marginCard}
            >
              <div className={classes.rotatingCardContainer}>
                <Card background className={classes.cardRotate}>
                  <ProgressiveImage src={imageRotating2} placeholder="tiny-image.jpg">
                    {(src, loading) => (
                      <div
                        className={classes.front + " " + classes.wrapperBackground}
                        style={{
                          backgroundImage: `url(${imageRotating2})`,
                        }}
                      >
                        <CardBody background className={classes.cardBodyRotate}>
                          <h4 className={classes.cardCategoryWhite}>
                            Speaker : <b>Shri Nimish Pandya</b>
                          </h4>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <h3 className={classes.cardTitleWhite}>
                              <em>Reaching God via Digital Tools</em>
                            </h3>
                          </a>
                         
                        </CardBody>
                      </div>
                    )}
                  </ProgressiveImage>

                  <div
                    className={classes.back + " " + classes.wrapperBackground}
                    style={{
                      background: "#664f2b",
                    }}
                  >
                    <CardBody background className={classes.cardBodyRotate}>
                     

                      <div className={classes.textCenter}>
                        <h3 style={{ color: "#fff" }}>
                          To Watch the video click on the below button.
                        </h3>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=bIJ30xA4NpA"
                        >
                          <Button color="danger" size="lg">
                            Watch
                          </Button>
                        </a>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem> */}
            {/* econd hover item end  */}

            {/* third hover items start */}
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className={classes.marginCard}
            >
              <div className={classes.rotatingCardContainer}>
                <Card background className={classes.cardRotate}>
                  <ProgressiveImage
                    src={musicBand}
                    placeholder="tiny-image.jpg"
                  >
                    {(src, loading) => (
                      <div
                        className={
                          classes.front + " " + classes.wrapperBackground
                        }
                        style={{
                          backgroundImage: `url(${musicBand})`,
                        }}
                      >
                        <CardBody background className={classes.cardBodyRotate}>
                          {/* <h4 className={classes.cardCategoryWhite}>
                            Speaker : <b>Smt Jaishree Bajaj</b>
                          </h4> */}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <h3 className={classes.cardTitleWhite}>
                              <em>SSSIHL BAND: Fusion of Jazz, Indian Classical and Vocals</em>
                            </h3>
                          </a>
                          {/* <p className={classes.cardDescriptionWhite}>
                                    Don{"'"}t be scared of the truth because we need to
                                    restart the human foundation in truth And I love you
                                    like Kanye loves Kanye I love Rick Owens’ bed design
                                    but the back is...
                                  </p> */}
                        </CardBody>
                      </div>
                    )}
                  </ProgressiveImage>

                  <div
                    className={classes.back + " " + classes.wrapperBackground}
                    style={{
                      background: "#4a260b",
                    }}
                  >
                    <CardBody background className={classes.cardBodyRotate}>
                      {/* <h5 className={classes.cardTitleWhite}>Manage Post</h5>
                        <p className={classes.cardDescriptionWhite}>
                          As an Admin, you have shortcuts to edit, view or
                          delete the posts.
                        </p>
                        <div className={classes.textCenter}>
                          <Button round justIcon color="info">
                            <Subject />
                          </Button>
                          <Button round justIcon color="success">
                            <Icon>mode_edit</Icon>
                          </Button>
                          <Button round justIcon color="danger">
                            <Delete />
                          </Button>
                        </div> */}
                      <div className={classes.textCenter}>
                        <h3 style={{ color: "#fff" }}>
                          To Watch the video click on the below button.
                        </h3>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=Tzd_9lo4un0"
                        >
                          <Button color="danger" size="lg">
                            Watch
                          </Button>
                        </a>
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </GridItem>
            {/* thir over item end */}
            {/* section 1  container start */}
            {/* harsh */}
            {/*  floating div */}
            {/* qoute start */}
            {/* <div className = {classes.container} className={classes.quoteMargin}>
          <GridContainer> */}
            {/* actual code */}
            {/* <div  className={classes.quoteMargin}>
            <GridContainer>
              <GridItem  md={6} sm={12} xs={12}>
                  <Card testimonial className={classes.card1}>
                    <div className={classes.iconQuoute}>
                      <FormatQuote /> 
                    </div>
                    <CardBody>
                      <h5 className={classes.cardDescription}>
                        Your products, all the kits that I have downloaded from your
                        site and worked with are sooo cool! I love the color
                        mixtures, cards... everything. Keep up the great work!
                      </h5>
                    </CardBody>
                    <CardFooter testimonial>
                      <h4 className={classes.cardTitle}>Alec Thompson</h4>
                      <h6 className={classes.cardCategory}>@ALECTHOMPSON</h6>
                      <CardAvatar testimonial testimonialFooter>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={cardProfile1Square} alt="..." />
                        </a>
                      </CardAvatar>
                    </CardFooter>
                  </Card>
                </GridItem>
              <GridItem md={6} sm={12} xs={12}>
                  <div className={classes.animeAreaImg}>
                    <ScrollAnimation animateIn="animate__fadeInUp">
                      <img
                        src={presentationiPadComments}
                        alt="iPad comments"
                        className={classes.areaImg}
                      />
                    </ScrollAnimation>
                  </div>
                  <div className={classes.animeInfoImg}>
                    <ScrollAnimation animateIn="animate__fadeInUp">
                      <img
                        src={presentationiPadTable}
                        alt="iPad table"
                        className={classes.infoImg}
                      />
                    </ScrollAnimation>
                  </div>
                  <img
                    className={classes.ipadImg}
                    src={presentationiPad}
                    alt="iPad"
                  />
              </GridItem>
              </GridContainer>
            </div> */}
            {/* actual code end */}
            {/* </GridContainer>
         
        </div> */}
            {/* ending floatig div */}

            {/* section end */}
          </GridContainer>
        </div>
      </div>
      <div>
        <GridContainer justify="center">
          <GridItem md={12}>
            {/* <Parallax
              blur={1}
              bgImage={require("assets/img/home/calender1.jpg")}
              bgImageAlt="the cat"
              strength={500}
            > */}
            <GridContainer
              style={{
                padding: "30px",
                background: "linear-gradient(128deg, rgba(203,12,98,1) 0%, rgba(237,159,95,1) 94%)",
                opacity: "1",
              }}
              justify="center"
            >
              <GridItem sm={12} md={12} lg={12}>
                <h2 className={classes.upcomingevents}>
                  <b>REGULAR EVENTS</b>
                </h2>
              </GridItem>
              <GridItem sm={4} md={3} className={classes.featuresShow}>
                <InfoArea
                  title="Regular Bhajan at SSSIC"
                  description={
                    <ul>
                      <li
                        style={{
                          listStyleType: "none",
                          marginLeft: "-35px",
                          textAlign: "center",
                        }}
                      >
                        <h4>Every Sunday 5.30PM - 6.30PM (Apr to Sept)</h4>
                        <h4>Every Sunday 5PM - 6PM (Oct to Mar)</h4>
                        <h4>[30 mins of Veda chanting prior to the bhajans]</h4>
                      </li>
                    </ul>
                  }
                  icon={CalendarTodayIcon}
                  iconColor="gray"
                  vertical={true}
                />
              </GridItem>

              <GridItem sm={4} md={3} className={classes.featuresShow}>
                <InfoArea
                  title="Regular Narayan Seva at SSSIC"
                  description={
                    //  <div>
                    <ul>
                      <li
                        style={{
                          listStyleType: "none",
                          marginLeft: "-35px",
                          textAlign: "center",
                        }}
                      >
                        <h4>
                          Every Sunday 11.30 AM onwards
                        </h4>
                      </li>
                    </ul>
                    //  </div>
                  }
                  icon={CalendarTodayIcon}
                  iconColor="gray"
                  vertical={true}
                />
              </GridItem>

              <GridItem sm={4} md={3} className={classes.featuresShow}>
                <InfoArea
                  title="Regular Dispensary Seva at SSSIC"
                  description={
                    //  <div>
                    <ul>
                      <li
                        style={{
                          listStyleType: "none",
                          marginLeft: "-35px",
                          textAlign: "center",
                        }}
                      >
                        <h4>
                          Every Sunday 9.45 AM onwards
                        </h4>
                      </li>
                    </ul>
                  }
                  icon={CalendarTodayIcon}
                  iconColor="gray"
                  vertical={true}
                />
              </GridItem>

              <GridItem sm={3} md={3} className={classes.featuresShow}>
                <InfoArea
                  title="Dispensary Calendar"
                  description={
                    <ul>
                      <li
                        style={{
                          listStyleType: "none",
                          marginLeft: "-35px",
                          textAlign: "center",
                        }}
                      >
                        <a
                          target="_blank"
                          href="https://docs.google.com/document/d/1Ls_Xp26MZ__zxXEyS31nYYa42zo2yHd4/edit?usp=share_link&ouid=105876362112638332676&rtpof=true&sd=true"
                          className={classes.featuresLink}>Click to open</a>
                      </li>
                    </ul>
                  }
                  icon={CalendarTodayIcon}
                  iconColor="gray"
                  vertical={true}
                />
              </GridItem>
            </GridContainer>
            {/* </Parallax> */}
          </GridItem>
        </GridContainer>
      </div>
      <div>
        <GridContainer justify="center">
          <GridItem md={12}>
            <Parallax
              blur={1}
              bgImage={require("assets/img/home/calender1.jpg")}
              bgImageAlt="the cat"
              strength={500}
            >
              <GridContainer
                style={{
                  padding: "30px",
                  background: "rgba(0, 0, 0, 0.87)",
                  opacity: "0.7",
                }}
                justify="center"
              >
                <GridItem sm={12} md={12} lg={12}>
                  <h2 className={classes.upcomingevents}>
                    <b>UPCOMING EVENTS</b>
                  </h2>
                </GridItem>

                <GridItem sm={3} md={3} className={classes.featuresShow}>
                  <InfoArea
                    title="Interaction with Shri. Ramu Etikala, Maintenance Manager Prashanti Nilayam"
                    description={
                      <ul>
                        <li
                          style={{
                            listStyleType: "none",
                            marginLeft: "-35px",
                            textAlign: "center",
                          }}
                        >
                          <h4 className={classes.calenderShadow}>
                            Sunday, 18 February 2024
                          </h4>
                        </li>
                      </ul>
                    }
                    icon={CalendarTodayIcon}
                    iconColor="gray"
                    vertical={true}
                  />
                </GridItem>

                <GridItem sm={3} md={3} className={classes.featuresShow}>
                  <InfoArea
                    title="Workshop on Omkar & Jyoti Meditation"
                    description={
                      <ul>
                        <li
                          style={{
                            listStyleType: "none",
                            marginLeft: "-35px",
                            textAlign: "center",
                          }}
                        >
                          <h4 className={classes.calenderShadow}>
                            Sunday, 25 February 2024
                          </h4>
                        </li>
                      </ul>
                    }
                    icon={CalendarTodayIcon}
                    iconColor="gray"
                    vertical={true}
                  />
                </GridItem>

                <GridItem sm={3} md={3} className={classes.featuresShow}>
                  <InfoArea
                    title="Mahashivratri Bhajan & Rudra Abhishekam"
                    description={
                      <ul>
                        <li
                          style={{
                            listStyleType: "none",
                            marginLeft: "-35px",
                            textAlign: "center",
                          }}
                        >
                          <h4 className={classes.calenderShadow}>
                            Friday, 8 March - Saturday, 9 March from 8 PM
                          </h4>
                        </li>
                      </ul>
                    }
                    icon={CalendarTodayIcon}
                    iconColor="gray"
                    vertical={true}
                  />
                </GridItem>

                <GridItem sm={3} md={3} className={classes.featuresShow}>
                  <InfoArea
                    title="Annual Calendar"
                    description={
                      <ul>
                        <li
                          style={{
                            listStyleType: "none",
                            marginLeft: "-35px",
                            textAlign: "center",
                          }}
                        >
                          <a
                            target="_blank"
                            href="https://docs.google.com/spreadsheets/d/1MWz2uD6v1A3c3aNy-kciuQ7nKaIIRsnj/edit?usp=sharing&ouid=101958197977952164894&rtpof=true&sd=true"
                            className={classes.featuresLink}>Click to open</a>
                        </li>
                      </ul>
                    }
                    icon={CalendarTodayIcon}
                    iconColor="gray"
                    vertical={true}
                  />
                </GridItem>

              </GridContainer>
            </Parallax>
          </GridItem>
        </GridContainer>
      </div>
      <div>
        <GridContainer justify="left">
          <GridItem md={6} className={classes.newInformation}>
            <p><b>Oxygen Concentrator Undertaking Form</b></p>
            <p>To facilitate the provision of oxygen concentrators, we require the following details:</p>
            <ul>
              <li>Name of patient</li>
              <li>Contact number</li>
              <li>Reference from any member of our organization</li>
              <li>Aadhar card of patient</li>
              <li>Address of patient</li>
            </ul>
            <p>For assistance, plase reach out to us on 9971999992. We will promptly respond to your request.</p>
          </GridItem>
          <GridItem md={6} className={classes.newInformation}>
            <p><b>Book Space at SSSIC</b></p>
            <p>Book our Sri Sathya Sai International Centre Auditorium or Basement Hall to find the perfect space for your events.</p>
            <p>To book, please contact us on 9313634380 and 011-24397820.</p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
