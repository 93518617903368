import React from "react";
import "./App.css";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import LandingNewPage from "views/LandingPage/LandingPageNew.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import WebsiteFooter from "components/Footer/WebsiteFooter";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import PageLoader from "views/PageLoader/PageLoader";

// lazyily load page components when needed
const DistrictPage = React.lazy(() =>
  import("views/DistrictPage/DistrictPage")
);
const DistrictWingPage = React.lazy(() =>
  import("views/DistrictWingPage/DistrictWingPage")
);
const SimplePage = React.lazy(() => import("views/SimplePage/SimplePage"));
const VedasPage = React.lazy(() => import("views/VedasPage/VedasPage"));
const BalvikasOverviewPage = React.lazy(() => import("views/BalvikasPage/OverviewPage"));
const BalvikasActivityReportsPage = React.lazy(() => import("views/BalvikasPage/ActivityReportsPage"));
const BalvikasAlumniPage = React.lazy(() => import("views/BalvikasPage/AlumniPage"));
const BalvikasCentersPage = React.lazy(() => import("views/BalvikasPage/CentersPage"));
const SSYMPage = React.lazy(() => import("views/SSYMPage/SSYMPage"));
const DonationPage = React.lazy(() => import("views/DonationPage/DonationPage"));
const MissionPage = React.lazy(() => import("views/LandingPage/MissionPage"));
const StoriesFromHisLife = React.lazy(() => import("views/LandingPage/StoriesFromLife"));
const SwamiQuotes = React.lazy(() => import("views/LandingPage/SwamiQuotes"));
const Snippets = React.lazy(() => import("views/LandingPage/snippets"));
const AboutSSSO = React.lazy(() => import("views/LandingPage/AboutSSSO"));
const AboutSSS = React.lazy(() => import("views/LandingPage/AboutSSS"));
const CodeOfConduct = React.lazy(() => import("views/LandingPage/codeOfConduct"));
const OfficeBearesPage = React.lazy(() => import("views/LandingPage/OfficeBearers"));
const AboutUsPage = React.lazy(() => import("views/AboutUsPage/AboutUsPage"));
const SectionsPage = React.lazy(() => import("views/SectionsPage/SectionsPage"));
const LoginPage = React.lazy(() => import("views/LoginPage/LoginPage"));
const Quotes = React.lazy(() => import("views/LandingPage/quotes"));
const Reports = React.lazy(() => import("views/LandingPage/Reports"));
const Components = React.lazy(() => import("views/Components/Components"));
const SpritialWing = React.lazy(() => import("views/LandingPage/spritualWing"));
const EducationWing = React.lazy(() => import("views/LandingPage/educationWing"));
const ServiceWing = React.lazy(() => import("views/LandingPage/serviceWing"));
const DistrictOfficeBearers = React.lazy(() => import("views/LandingPage/DistrictOfficebearers"));
const UnderConstructionPage = React.lazy(() => import("views/UnderConstructionPage/UnderConstructionPage"));

function App() {
  var hist = createBrowserHistory();
  return (
    <div className="App">
      <Router history={hist}>
        <ScrollToTop />
        <Header
          color="customColor"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
        />
        <React.Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact component={LandingNewPage} />
            <Route
              path="/district/:districtName/wing/:wingName"
              component={DistrictWingPage}
            />
            <Route path="/district/:districtName" component={DistrictPage} />
            <Route path="/mission" component={MissionPage} />
            <Route path="/storiesFromHisLife" component={StoriesFromHisLife} />
            <Route path="/swamiQuotes" component={SwamiQuotes} />
            <Route path="/snippets" component={Snippets} />

            <Route path="/aboutSSSO" component={AboutSSSO} />
            <Route path="/aboutSSS" component={AboutSSS} />
            <Route path="/codeOfConduct" component={CodeOfConduct} />
            <Route path="/officeBearers" component={OfficeBearesPage} />
            <Route path="/profile-page" component={AboutUsPage} />
            <Route path="/sections" component={SectionsPage} />
            <Route path="/login-page" component={LoginPage} />
            <Route path="/quotes" component={Quotes} />
            <Route path="/reports" component={Reports} />
            <Route path="/landing-page" component={Components} />
            <Route path="/spritual-wing" component={SpritialWing} />
            <Route path="/education-wing" component={EducationWing} />
            <Route path="/service-wing" component={ServiceWing} />
            <Route path="/dist-off-bearers" component={DistrictOfficeBearers} />
            <Route path="/special-initiative/vedas" component={VedasPage} />
            <Route path="/special-initiative/:simplePageDataID" component={SimplePage} />
            <Route path="/balvikas/centers" component={BalvikasCentersPage}/>
            <Route path="/balvikas/alumni" component={BalvikasAlumniPage}/>
            <Route path="/balvikas/activity-reports" component={BalvikasActivityReportsPage}/>
            <Route path="/balvikas" component={BalvikasOverviewPage}/>
            <Route path="/ssym" component={SSYMPage}/>
            <Route path="/donate" component={DonationPage}/>
            <Route
              path="/special-initiative/:simplePageDataID"
              component={SimplePage}
            />
            <Route
              path="/under-construction"
              component={UnderConstructionPage}
            />
          </Switch>
        </React.Suspense>
        <WebsiteFooter />
      </Router>
    </div>
  );
}

export default App;
