/**
 * @format
 */

import ClearHeader from "components/ClearHeader/ClearHeader";
import React from "react";
import logo from "../../assets/img/logo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  page: {
    height: "calc(100vh - 84px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    marginBottom: "10px",
  },
  loadingText: {
    color: "#3C4858",
    fontSize: "1.4rem",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  },
});

export default function PageLoader() {
  const classes = useStyles();

  return (
    <>
      <ClearHeader />
      <div className={classes.page}>
        <div>
          <img src={logo} className={classes.logoImage} />
          <div className={classes.loadingText}>Loading...</div>
        </div>
      </div>
    </>
  );
}
