/**
 * @format
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  clearHeader: {
    paddingTop: "76px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "84px",
    },
  },
}));

export default function ClearHeader() {
  const classes = useStyles();

  return <div className={classes.clearHeader}></div>;
}
