import {
  container,
  title,
  main,
  whiteColor,
  mainRaised,
  mlAuto,
  mrAuto,
  cardTitle,
  description,
  coloredShadow,
  grayColor,
  hexToRgb,
  blackColor,
  section,
} from "assets/jss/material-kit-pro-react.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import rotatingCards from "assets/jss/material-kit-pro-react/rotatingCards.js";

const landingPageNewStyle = (theme) => ({
  mlAuto,
  mrAuto,
  ...imagesStyles,
  cardTitle,
  coloredShadow,
  description,
  ...rotatingCards,
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2",
  },
  overrideMaiRaised: {
    margin: "0 !important",
  },
  itemposition: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& h2": {
      color: "black",
    },
  },
  img: {
    width: "20%",
    marginRight: "5%",
    marginBottom: "5%",
    float: "left",
  },
  cardColorWhite: {
    color: "#fff",
  },
  iconQuoute: {
    marginTop: "30px",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "40px",
      lineHeight: "40px",
    },
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },

  email: {
    textTransform: "initial",
  },

  cardDescription: {
    ...description,
  },

  card1: {
    "& $cardDescription": {
      marginBottom: "50px",
      fontStyle: "italic",
    },
  },

  section: {
    // ...section,
    width: "100%",
    padding: "30px 0px",
  },
  features5: {
    ...section,
    backgroundRepeat: "no-repeat",
    position: "relative",
    padding: "80px 0px",
    textAlign: "center",
    "& $title": {
      zIndex: "2",
      position: "relative",
      marginBottom: "30px",
      color: whiteColor,
    },
    "& p": {
      color: whiteColor,
    },
    "&:after": {
      background: "rgba(" + hexToRgb(blackColor) + ",0.65)",
      position: "absolute",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "0",
      left: "0px",
      top: "0px",
    },
  },
  featuresShow: {
    zIndex: 3,
    border: "1px solid rgba(" + hexToRgb(whiteColor) + ",0.35)",
    borderTop: 0,
    borderBottom: 0,
    "&:last-of-type": {
      borderRight: 0,
    },
    "&:first-of-type": {
      borderLeft: 0,
    },
    "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: whiteColor,
    },
    "& p": {
      lineHeight: "24px",
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      // border: "1px solid rgba(" + hexToRgb(whiteColor) + ",0.35)",
      borderRight: "1px solid rgba(" + hexToRgb(whiteColor) + ",0.35)",
      borderLeft: "1px solid rgba(" + hexToRgb(whiteColor) + ",0.35)",
      borderBottom: 0,
      "&:last-of-type": {
        borderRight: "1px solid rgba(" + hexToRgb(whiteColor) + ",0.35)",
      },
    },
  },

  cardCategory: {
    marginBottom: "0",
    marginTop: "10px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "8px",
      lineHeight: "0",
    },
  },

  pageHeader: {
    position: "relative",
    marginTop: "4rem",
    height: "calc(100vh - 4rem)",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    // "&:before": {
    //   background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    // },
    // "&:after,&:before": {
    //   position: "absolute",
    //   zIndex: "1",
    //   width: "100%",
    //   height: "100%",
    //   display: "block",
    //   left: "0",
    //   top: "0",
    //   content: "''"
    // }
  },

  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    ...main,
  },
  mainRaised: {
    ...mainRaised,
  },
  pullCenter: {
    display: "inline-block",
    float: "none",
  },
  copyRight: {
    padding: "15px 0px",
  },
  footer: {
    "& ul li": {
      display: "inline-block",
    },
    "& h4, & h5": {
      color: grayColor[1],
      textDecoration: "none",
    },
    "& ul:not($socialButtons) li a": {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block",
    },
    "& small": {
      fontSize: "75%",
      color: grayColor[10],
    },
    "& $pullCenter": {
      display: "inline-block",
      float: "none",
    },
  },

  socialFeed: {
    "& p": {
      display: "inline-block",
      verticalAlign: "top",
      overflow: "hidden",
      paddingBottom: "10px",
      maxWidth: 300,
      marginLeft: "11px",
    },
    "& i": {
      fontSize: "20px",
      display: "table-cell",
      paddingRight: "10px",
    },
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },

  imageContainer: {
    maxWidth: "900px",
    position: "relative",
  },

  animeAreaImg: {
    top: "10%",
    left: "60%",
    position: "absolute",
  },
  areaImg: {
    height: "300px",
    width: "250px",
    zIndex: 2,
    top: "10%",
    left: "60%",
    boxShadow:
      "0 8px 10px 1px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 3px 14px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 5px 5px -3px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "130px",
      maxHeight: "170px",
    },
  },

  upcomingevents: {
    color: "#fff",
    animation: "$mymove 5s infinite",
  },
  "@keyframes mymove": {
    "60%": { textShadow: "2px 20px 30px #fff", transform: "scale(1.1)" },
    "80%": { textShadow: "30px 30px 70px #fff", transform: "scale(1.2)" },
    "0%": { textShadow: "30px 30px 70px #fff", transform: "scale(1)" },
  },

  calenderShadow: {
    textShadow: "6px 4px 6px #fbfbfb",
  },

  quoteMargin: {
    margin: "90px 0",
  },
  marginCard: {
    marginTop: "30px",
    marginBottom: "7rem",
  },
  infoImg: {
    height: "200px",
    width: "300px",
    // top: "50%",
    // left: "0%",
    marginLeft: "-37%",
    marginTop: "5%",
    boxShadow:
      "0 8px 10px 1px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 3px 14px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 5px 5px -3px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "170px",
      maxHeight: "120px",
    },
  },

  ipadImg: {
    width: "100%",
  },

  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  featuresLink: {
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    marginTop: "10px",
    marginBottom: "10px",
    textDecoration: "underline",
    color: "white",
    display: "inline-block"
  },
  newInformation: {
    fontSize: '20px',
    textAlign: 'left',
    padding: '30px 60px',
    color: 'black',
    fontWeight: '400',
    "& p": {
      fontSize: '20px',
    }
  }
});

export default landingPageNewStyle;
